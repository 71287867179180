var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        width: "30",
        height: "50",
        viewBox: "0 0 30 50",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("path", {
        attrs: {
          d: "M5.66667 0C2.55555 0 0 2.5 0 5.66667V44.3333C0 47.4444 2.55555 50 5.66667 50H23.8333C27 50 29.5556 47.4444 29.5556 44.2778V5.66667C29.5556 2.5 27 0 23.8333 0H5.66667ZM19.6111 4.83333C20.2778 4.83333 20.7778 5.33333 20.7778 5.94444C20.7778 6.61111 20.2778 7.11111 19.6111 7.11111H10.5C9.83333 7.11111 9.33333 6.61111 9.33333 5.94444C9.33333 5.33333 9.83333 4.83333 10.5 4.83333H19.6111V4.83333ZM15.0556 45.8333C13.7778 45.8333 12.7778 44.7778 12.7778 43.5C12.7778 42.2222 13.7778 41.2222 15.0556 41.2222C16.3333 41.2222 17.3333 42.2222 17.3333 43.5C17.3333 44.7778 16.3333 45.8333 15.0556 45.8333ZM26.5 37.9444C26.5 38.6111 25.9444 39.1667 25.2778 39.1667H4.83333C4.16667 39.1667 3.66667 38.6111 3.66667 37.9444V10.7222C3.66667 10.0556 4.22222 9.5 4.83333 9.5H25.3333C26 9.5 26.5556 10.0556 26.5556 10.7222V37.9444H26.5Z",
          fill: "white",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }