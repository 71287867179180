var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        width: "77",
        height: "50",
        viewBox: "0 0 77 50",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("path", {
        attrs: {
          d: "M7.16705 0C3.19565 0 0 3.19568 0 7.16705V42.8329C0 46.8043 3.19565 50 7.16705 50H51.514C55.4854 50 58.6811 46.8043 58.6811 42.8329V34.7277L71.4199 44.2081C74.5375 45.8093 76.2702 44.0776 76.2702 40.9159V9.08413C76.2702 5.92256 74.5375 4.19092 71.4199 5.7919L58.6811 15.2723V7.16705C58.6811 3.19568 55.4854 0 51.514 0H7.16705Z",
          fill: "white",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }