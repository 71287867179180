<template>
    <div class="prefooter-blocks-small">
        <div class="block-wrap" v-if="showFirst">
            <div class="block heading-block">
                <div><p>Hai bisogno di aiuto?</p></div>
            </div>
        </div>

        <div class="block-wrap commonBlock faq">
            <div class="block" @click="mixinGoTo('faq',{})">
                <div class="helpIcon"><Question /></div>
                <p>
                    Domande frequenti
                    <br>
                    <u>Leggi!</u>
                </p>
            </div>
        </div>

        <div class="block-wrap commonBlock chat">
            <a class="block" v-bind:href="'javascript:window.zE.activate()'">
                <div class="helpIcon"><Chat /></div>
                <p>
                    Online chat
                    <br>
                    <u>Chatta ora!</u>
                </p>
            </a>
        </div>

        <div class="block-wrap commonBlock whatsapp">
            <a class="block" v-bind:href="config.site.waIntentLink" target="_blank">
                <div class="helpIcon"><Whatsapp /></div>
                <p>
                    Whatsapp
                    <br><small>{{config.site.mobileNumber}}</small>
                    <u>Chatta ora! </u>
                </p>
            </a>
        </div>

        <div class="block-wrap commonBlock mail">
            <a class="block" v-bind:href="'mailto:' + config.site.emailSupport">
                <div class="helpIcon"><Mail /></div>
                <p>
                    Assistenza email
                    <br><small>{{config.site.emailSupport}}</small>
                    <u>Scrivi!</u>
                </p>
            </a>
        </div>

        <div class="block-wrap commonBlock phone">
            <a class="block" v-bind:href="'phone:' + config.site.phoneNumber">
                <div class="helpIcon"><Phone /></div>
                <p>
                    {{config.site.phoneNumber}}
                    <br><u>Chiama ora!</u>
                </p>
            </a>
        </div>

        <div v-if="config.site.urlCall" class="block-wrap commonBlock mobile">
            <a class="block" v-bind:href="config.site.urlCall" target="_blank">
                <div class="helpIcon"><MobilePhone /></div>
                <p>
                    Ti richiamiamo noi!
                    <br>
                    <small>Scegli quando preferisci essere ricontattato.</small>
                    <u>Scegli quando!</u>
                </p>
            </a>
        </div>

        <div v-if="config.site.urlVideoCall" class="block-wrap commonBlock video">
            <a class="block" v-bind:href="config.site.urlVideoCall" target="_blank">
                <div class="helpIcon"><VideoCall /></div>
                <p>
                    Fissa una video call
                    <br>
                    <small>Naviga il sito insieme ad un nostro assistente.</small>
                    <u>Scegli quando!</u>
                </p>
            </a>
        </div>
        
        <div class="block-wrap commonBlock orari">
            <div>
                <p>
                    Lun-ven 9:00 - 19:00
                    <br>
                    <span v-if="config.site.urlCall || config.site.urlVideoCall">Call back 9:30 - 18:00</span>
                </p>
            </div>
        </div>

        <!-- questo aggiunge un box vuoto nel caso ci sia solamente una url call/videocall impostata, così rimane tutto allineato-->
        <div v-if="!config.site.urlCall || !config.site.urlVideoCall" class="block-wrap commonBlock video">
          <div>
            <p>
              &nbsp;
            </p>
          </div>
        </div>
        
    </div>    
</template>

<script>
import Vue from "vue"
import Phone from '../../svg/phone'
import Mail from '../../svg/mail'
import Whatsapp from '../../svg/whatsapp'
import Chat from '../../svg/chat'
import Question from '../../svg/question'
import MobilePhone from '../../svg/mobile-phone'
import VideoCall from '../../svg/video-call'

export default {
    name: 'prefooter-gold',
    components:
    {
        Phone, Mail, Whatsapp, Chat, Question, MobilePhone, VideoCall,
    },
    props:
    {
      showFirst: {
        type: Boolean,
        default: false,
      }
    },
    data()
    {
        return {
            config: Vue.prototype.$config
        }
    }
}
</script>

<style lang="scss">
    @import "~bootstrap/scss/functions";
    @import "~bootstrap/scss/mixins";
    @import './../../../scss/_custom.scss';
    
    .prefooter-blocks-small
    {

        *,*:hover
        {
            color:$white;
            text-decoration: none;
        }

        u,u:hover
        {
            text-decoration:underline;
        }

        @include media-breakpoint-down(xs)
        {
            padding:1.25rem 1.25rem;
            .block
            {
                min-height: 0 !important;
                padding-bottom:1rem !important;
                justify-content: flex-start !important;
                svg
                {
                    margin-right:1rem !important;
                    margin-top:0 !important;
                    transform:translateY(6px);
                }
            }
            .commonBlock:last-child .block
            {
                padding-bottom:0 !important;
            }
        }

        font-weight:400;
        background:$dark-blue;
        border-radius: $border-radius;
        $block-pad: $grid-gutter-width/2;
        font-weight:600;

        display:flex;
        flex-wrap:wrap;
        justify-content:center;
        padding-top:$block-pad;

        @include media-breakpoint-up(xxl)
        {
            justify-content: space-between;
        }

        .block-wrap
        {
            flex:100%;
            .block
            {
                margin:auto;
                justify-content: center;
            }
        }

        .block
        {
            display:flex;
            justify-content: center;
            align-items: flex-start;
            font-size:14px;
            min-height:4rem;
            position:relative;
            p
            {
                margin-bottom:0;
            }
            small
            {
                display:block;
            }
        }

        .heading-block
        {
            align-items:flex-start;
            justify-content:center !important;
            p
            {
                background:$white;
                color:$dark-blue;
                margin:0;
                padding:6px 14px !important;
                border-radius: 16px;
            }
            svg
            {
                width:48px;
                path
                {
                    fill:$white;
                }
            }

            p
            {
                padding:4px 0;
            }
        }

        .block-wrap *,
        .commonBlock *:hover
        {
            //color:$white;
            //text-decoration:none;
        }
        
        @include media-breakpoint-only(md)
        {
            .block
            {
                margin:auto;
            }
        }

        @include media-breakpoint-up(xl)
        {
            .block
            {
                min-height:0;
                display:flex;
                justify-content: flex-start;
                align-content:center;
                height:100%;
            }
        }

        .commonBlock
        {
            margin-bottom:$block-pad/2;
            &:first-child
            {
                margin-top:$block-pad/2;
            }
            .block
            {
                width:100%;
                justify-content: start;
            }
            .helpIcon
            {
                min-width: 40px;
                text-align:left;
            }
            svg
            {
                width:24px;
                height:auto;
                //margin:0.25rem 0.5rem 0 0.5rem;
            }

            path
            {
                fill:$white;
            }

            &.mobile svg
            {
                width:18px;
            }         
        }

        .orari
        {
            padding:$block-pad/2;
            background: $white;
            border-radius: $border-radius-lg;
            display:flex;
            align-items: center;
            justify-content: center;
            *
            {
                color:$dark-blue;
                font-size: 14px;
            }
            p
            {
                margin-bottom: 0.25rem;
            }
        }



        @include media-breakpoint-up(sm)
        {
            .commonBlock
            {
                margin:0 2.5%;
                max-width:42.5%;
                margin-bottom:$block-pad;
                &:first-child
                {
                    margin-top:0;
                }
            }
            .prefooter-blocks-small
            {
                justify-content: space-around;
            }
        }     
        @include media-breakpoint-up(md)
        {
            margin-top:0 !important;
            .commonBlock
            {
                margin:0 2.5%;
                max-width:20%;
                margin-bottom:$block-pad;
            }
            .faq{order:1;}
            .chat{order:2;}
            .whatsapp{order:3;}
            .mail{order:4;}
            .orari{order:5;}
            .phone{order:6;}
            .mobile{order:7;}
            .video{order:8;}
        }     
        @include media-breakpoint-up(lg)
        {
        }     
        @include media-breakpoint-up(xxl)
        {
            .commonBloxk
            {
                max-width:20%;
            }
            .block
            {
                max-width: 100%;
                margin-left: 0;
                margin-right: 0;
                p
                {
                    font-size:13px;
                }
            }
        }   
    }
</style>