var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "prefooter-blocks-small" }, [
    _vm.showFirst
      ? _c("div", { staticClass: "block-wrap" }, [_vm._m(0)])
      : _vm._e(),
    _c("div", { staticClass: "block-wrap commonBlock faq" }, [
      _c(
        "div",
        {
          staticClass: "block",
          on: {
            click: function ($event) {
              return _vm.mixinGoTo("faq", {})
            },
          },
        },
        [_c("div", { staticClass: "helpIcon" }, [_c("Question")], 1), _vm._m(1)]
      ),
    ]),
    _c("div", { staticClass: "block-wrap commonBlock chat" }, [
      _c(
        "a",
        {
          staticClass: "block",
          attrs: { href: "javascript:window.zE.activate()" },
        },
        [_c("div", { staticClass: "helpIcon" }, [_c("Chat")], 1), _vm._m(2)]
      ),
    ]),
    _c("div", { staticClass: "block-wrap commonBlock whatsapp" }, [
      _c(
        "a",
        {
          staticClass: "block",
          attrs: { href: _vm.config.site.waIntentLink, target: "_blank" },
        },
        [
          _c("div", { staticClass: "helpIcon" }, [_c("Whatsapp")], 1),
          _c("p", [
            _vm._v("\n                Whatsapp\n                "),
            _c("br"),
            _c("small", [_vm._v(_vm._s(_vm.config.site.mobileNumber))]),
            _c("u", [_vm._v("Chatta ora! ")]),
          ]),
        ]
      ),
    ]),
    _c("div", { staticClass: "block-wrap commonBlock mail" }, [
      _c(
        "a",
        {
          staticClass: "block",
          attrs: { href: "mailto:" + _vm.config.site.emailSupport },
        },
        [
          _c("div", { staticClass: "helpIcon" }, [_c("Mail")], 1),
          _c("p", [
            _vm._v("\n                Assistenza email\n                "),
            _c("br"),
            _c("small", [_vm._v(_vm._s(_vm.config.site.emailSupport))]),
            _c("u", [_vm._v("Scrivi!")]),
          ]),
        ]
      ),
    ]),
    _c("div", { staticClass: "block-wrap commonBlock phone" }, [
      _c(
        "a",
        {
          staticClass: "block",
          attrs: { href: "phone:" + _vm.config.site.phoneNumber },
        },
        [
          _c("div", { staticClass: "helpIcon" }, [_c("Phone")], 1),
          _c("p", [
            _vm._v(
              "\n                " +
                _vm._s(_vm.config.site.phoneNumber) +
                "\n                "
            ),
            _c("br"),
            _c("u", [_vm._v("Chiama ora!")]),
          ]),
        ]
      ),
    ]),
    _vm.config.site.urlCall
      ? _c("div", { staticClass: "block-wrap commonBlock mobile" }, [
          _c(
            "a",
            {
              staticClass: "block",
              attrs: { href: _vm.config.site.urlCall, target: "_blank" },
            },
            [
              _c("div", { staticClass: "helpIcon" }, [_c("MobilePhone")], 1),
              _vm._m(3),
            ]
          ),
        ])
      : _vm._e(),
    _vm.config.site.urlVideoCall
      ? _c("div", { staticClass: "block-wrap commonBlock video" }, [
          _c(
            "a",
            {
              staticClass: "block",
              attrs: { href: _vm.config.site.urlVideoCall, target: "_blank" },
            },
            [
              _c("div", { staticClass: "helpIcon" }, [_c("VideoCall")], 1),
              _vm._m(4),
            ]
          ),
        ])
      : _vm._e(),
    _c("div", { staticClass: "block-wrap commonBlock orari" }, [
      _c("div", [
        _c("p", [
          _vm._v("\n                Lun-ven 9:00 - 19:00\n                "),
          _c("br"),
          _vm.config.site.urlCall || _vm.config.site.urlVideoCall
            ? _c("span", [_vm._v("Call back 9:30 - 18:00")])
            : _vm._e(),
        ]),
      ]),
    ]),
    !_vm.config.site.urlCall || !_vm.config.site.urlVideoCall
      ? _c("div", { staticClass: "block-wrap commonBlock video" }, [_vm._m(5)])
      : _vm._e(),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "block heading-block" }, [
      _c("div", [_c("p", [_vm._v("Hai bisogno di aiuto?")])]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _vm._v("\n                Domande frequenti\n                "),
      _c("br"),
      _c("u", [_vm._v("Leggi!")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _vm._v("\n                Online chat\n                "),
      _c("br"),
      _c("u", [_vm._v("Chatta ora!")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _vm._v("\n                Ti richiamiamo noi!\n                "),
      _c("br"),
      _c("small", [_vm._v("Scegli quando preferisci essere ricontattato.")]),
      _c("u", [_vm._v("Scegli quando!")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _vm._v("\n                Fissa una video call\n                "),
      _c("br"),
      _c("small", [_vm._v("Naviga il sito insieme ad un nostro assistente.")]),
      _c("u", [_vm._v("Scegli quando!")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [_c("p", [_vm._v("\n           \n        ")])])
  },
]
render._withStripped = true

export { render, staticRenderFns }